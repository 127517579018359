.btn {
  color: #fff;
  font-size: 17px;
  display: inline-block;
  text-align: center;
}

/* Colors */
.btnPrimary {
  background-color: var(--blue);
}

.btnPrimary:hover {
  background-color: var(--blue-darkest);
  color: #fff;
}

.btnSecondary {
  background-color: var(--gray);
}

.btnSecondary:hover {
  background-color: var(--blue-darkest);
  color: #fff;
}

.btnTertiary {
  background-color: var(--green);
}

.btnTertiary:hover {
  background-color: var(--green-dark);
  color: #fff;
}

.btnCuaternary {
  background-color: var(--gray-medium);
  color: var(--gray-darkest);
}

.btnCuaternary:hover {
  background-color: var(--blue-darkest);
  color: #fff;
}

.ghostPrimary {
  background-color: none;
  border: 1.5px solid var(--green);
  color: #fff;
}

.ghostPrimary:hover {
  background-color: var(--green);
  color: #fff;
}

.ghostSecondary {
  background-color: none;
  border: 1.5px solid var(--blue);
  color: var(--blue);
}

.ghostSecondary:hover {
  background-color: var(--blue);
  color: #fff;
}

.ghostTertiary {
  background-color: none;
  border: 1.5px solid var(--gray-light);
  color: var(--gray-light);
}

.ghostTertiary:hover {
  background-color: var(--gray-light);
  color: var(--blue-darkest);
}

/* Sizes */
.btnSmall {
  padding: 0.9rem 1.25rem;
}

.btnMedium {
  width: 32%;
  padding: 0.9rem 2rem;
}

.btnLarge {
  width: 48.9%;
  padding: 0.9rem 3rem;
}

.btnFull {
  width: 100%;
  padding: 1rem 1.25rem;
}

@media only screen and (max-width: 575px) {
  .btnMedium,
  .btnLarge {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btnMedium {
    width: 48.9%;
  }
}

/* Styles for spinner */
@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.spinner div {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 10px solid rgba(255, 255, 255, 0.4);
  border-top-color: transparent;
  border-radius: 50%;
}

.spinner div {
  animation: spinner 1s linear infinite;
  top: 50px;
  left: 50px;
}

.spinnerContainer {
  width: 14px;
  height: 14px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.14);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.spinner div {
  box-sizing: content-box;
}
